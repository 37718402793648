export enum AcademicYearFilter {
    academicYearId,
    clientId,
    organizationId,
    institutionId,
    name,
    code,
    startDate,
    endDate,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum AdmissionCategoryFilter {
    admissionCategoryId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum BatchFilter {
    batchId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    courseId,
    startDate,
    endDate,
    isActive,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum CityFilter {
    cityId,
    clientId,
    organizationId,
    institutionId,
    countryId,
    countryCode,
    stateId,
    stateCode,
    districtId,
    districtCode,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum CountryFilter {
    countryId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum CourseFilter {
    courseId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    courseTypeId,
    courseAlias,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    form_charge,
    isSplitPayment,
    account_1_share,
    account_2_share
}
export enum DistrictFilter {
    districtId,
    clientId,
    organizationId,
    institutionId,
    countryId,
    countryCode,
    stateId,
    stateCode,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum HolidayFilter {
    holidayId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    holidayDate,
    academicYearId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum NationalityFilter {
    nationalityId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    isDefault,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum SectionFilter {
    sectionId,
    clientId,
    organizationId,
    institutionId,
    code,
    name,
    courseId,
    batchId,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    conferenceRoomId,
}
export enum StateFilter {
    stateId,
    clientId,
    organizationId,
    institutionId,
    countryId,
    countryCode,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
}
export enum SubjectFilter {
    subjectId,
    clientId,
    organizationId,
    institutionId,
    courseId,
    batchId,
    academicYearId,
    code,
    name,
    isActive,
    status,
    rev,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    sectionId,
    isExternal,
    isInternal,
    isPreTest
}
