import { LayoutComponent } from '../layout/layout.component';
// import { LoginComponent } from './security/login/login.component';
import { ForgotPasswordComponent } from './security/forgot-password/forgot-password';
import { PageComponent } from './application/page/page-component';
import { PaymentSuccessComponent } from './general/payment-success/payment-success.component';
import { PaymentFailComponent } from './general/payment-fail/payment-fail.component';
import { NavjyotiLoginComponent } from './security/navjyoti-login/navjyoti-login.component';


export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'general', loadChildren: './general/general.module#GeneralModule' },
            { path: 'configuration', loadChildren: './configuration/configuration.module#ConfigurationModule' },
            { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
            { path: 'hrmconfig', loadChildren: './hrmconfig/hrmconfig.module#HRMConfigModule' },
            { path: 'hrmemp', loadChildren: './hrmemp/hrmemp.module#HRMEmpModule' },
            { path: 'student', loadChildren: './student/student.module#StudentModule' },
            { path: 'superadmin', loadChildren: './superadmin/superadmin.module#SuperAdminModule' },
            { path: 'fee', loadChildren: './fee/fee.module#FeeModule' },
            { path: 'attendance', loadChildren: './attendance/attendance.module#AttendanceModule' },
            { path: 'staff', loadChildren: './staff/staff.module#StaffModule' },
            { path: 'hostelmgmt', loadChildren: './hostelmgmt/hostelmgmt.module#HostelMgmtModule' },
            { path: 'transport', loadChildren: './transport/transport.module#TransportModule' },
            { path: 'rpt', loadChildren: './rpt/report.module#ReportModule' },
            { path: 'videoconference', loadChildren: './videoconference/videoconference.module#VideoConferenceModule' },
            { path: 'payroll', loadChildren: './payroll/payroll.module#PayrollModule' },
            { path: 'assessment', loadChildren: './assessment/assessment.module#AssessmentModule' },
            { path: 'library', loadChildren: './library/library.module#LibraryModule' },
            { path: 'accounts', loadChildren: './accounts/accounts.module#AccountsModule' },
            { path: 'assessmentform', loadChildren: './assessmentform/assessmentform.module#AssessmentFormModule' },
            { path: 'syllabus', loadChildren: './syllabus/syllabus.module#SyllabusModule' }
        ]
    },

    { path: 'login', component: NavjyotiLoginComponent },
    { path: 'payment-success', component: PaymentSuccessComponent },
    { path: 'payment-fail', component: PaymentFailComponent },
    { path: 'forgotpassword', component: ForgotPasswordComponent },
    // Not found
    { path: '**', redirectTo: 'login' }

];
