// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  isRazorPayActive: true,
  RazorPayKey: 'rzp_test_y6zOAVGCadNeH5',
  RazorPaySecretKey: '9yxp2tLWqv0nxJBhpmhcNLiR',
  RazorPayMidKey: 'JMK9gpTTAXDn8q',
  RazorPayAccount1: 'acc_LJ3FEPSlTJhqvw',
  RazorPayAccount2: 'acc_LHpJDcl339TN63',
};
